import classes from "./Card.module.css";

const Card = (props) => {
  return (
    <div className={classes["nft-card"] + " " + props.className}>
      <div className={classes.img}>
        <div
          style={{
            backgroundImage: `url(${props.src})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
      <div className={classes.text}>
        <h5>{props.label}</h5>
        <p>{props.desc}</p>
      </div>
    </div>
  );
};

export default Card;
