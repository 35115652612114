import classes from "./Navbar-Nav.module.css";

import NavItem from "./Nav-link";
import { Fragment, useContext } from "react";
import Icon from "../../UI/Icon";
import DarkMode from "../../UI/DarkMode";

import XIcon from "../../assets/Icons/XIconBlack.png";
import OpenSeaIcon from "../../assets/Icons/OpenSeaIconBlack.png";
import DiscordIcon from "../../assets/Icons/DiscordIconBlack.png";

import XIconDark from "../../assets/Icons/XIcon.png";
import OpenSeaIconDark from "../../assets/Icons/OpenSeaIcon.png";
import DiscordIconDark from "../../assets/Icons/DiscordIcon.png";

import darkModeContext from "../../store/mode-context";

const NavbarNav = (props) => {
  const darkModeCtx = useContext(darkModeContext);

  return (
    <div>
      <ul class={"navbar-nav ms-auto " + classes.ul}>
        {props.type == "menu" ? (
          <div className={classes.list}>
            <NavItem link="#mint" label="MINT" />
            <NavItem link="#about" label="ABOUT" />
            <NavItem link="#gachapon" label="GACHAPON" />
            <NavItem link="#team" label="TEAM" />
            <NavItem link="#faq" label="FAQ" />
          </div>
        ) : (
          <div className={classes.icons}>
            <Icon
              link="https://twitter.com/YakuzaIncNFT"
              src={darkModeCtx.darkMode ? XIconDark : XIcon}
              alt="X icon"
            />
            <Icon
              link="https://discord.gg/5pny74CwA4"
              src={darkModeCtx.darkMode ? DiscordIconDark : DiscordIcon}
              alt="Discord icon"
            />
            <Icon
              link="https://opensea.io/collection/yakuza-inc-official"
              src={darkModeCtx.darkMode ? OpenSeaIconDark : OpenSeaIcon}
              alt="OpenSea icon"
            />
            <DarkMode className="d-md-block d-none" />
          </div>
        )}
      </ul>
    </div>
  );
};

export default NavbarNav;
