import { Fragment } from "react";
import classes from "./Gachapon-Text.module.css";

const GachaponText = (props) => {
  return (
    <Fragment>
      <h3 className={classes.title + " " + (props.darkMode && classes.dark)}>
        YAKUZA GACHAPON
      </h3>
      <p className={classes.text + " " + (props.darkMode && classes.dark)}>
        Prepare to unlock the potential of your{" "}
        <span className="red-color">$TEMPURA</span> tokens with our upcoming
        Yakuza Gachapon! This exciting feature offers the chance to win cash
        prizes, secure coveted whitelist spots for high-demand projects, and
        acquire unique NFTs. But remember, fortune favors the brave — there's
        always the thrilling possibility of a miss, encouraging you to try your
        luck again.
      </p>
    </Fragment>
  );
};

export default GachaponText;
