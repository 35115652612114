import { createContext, useState, useEffect } from "react";

const darkModeContext = createContext({
  darkMode: false,
  SwitchDarkMode: () => {},
});

export const DarkModeProvider = (props) => {
  const savedDarkMode = JSON.parse(localStorage.getItem("darkMode")) || false;
  const [darkMode, setDarkMode] = useState(savedDarkMode);

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  const ChangeDarkModeHandler = () => {
    setDarkMode(!darkMode);
  };

  return (
    <darkModeContext.Provider
      value={{
        darkMode: darkMode,
        SwitchDarkMode: ChangeDarkModeHandler,
      }}
    >
      {props.children}
    </darkModeContext.Provider>
  );
};

export default darkModeContext;
