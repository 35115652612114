import classes from "./Team.module.css";
import { Fade } from "react-reveal";

import { useContext } from "react";

import Container from "../../UI/Container";
import Title from "../../UI/Title";
import NextSection from "../../UI/NextSection";

import TeamCard from "./Team-Card";

import DMC from "../../assets/Imgs/Dmc.gif";
import Arhood from "../../assets/Imgs/Arhood.png";
import YakuzaDaddy from "../../assets/Imgs/YakuzaDaddy.png";
import Gizmo from "../../assets/Imgs/Gizmo.png";
import Slutty from "../../assets/Imgs/Slutty.png";
import Amanda from "../../assets/Imgs/Amanda.png";

import darkModeContext from "../../store/mode-context";

const Team = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <section
      className={classes.team + " " + (darkModeCtx.darkMode && classes.dark)}
      id="team"
    >
      <Container>
        <Title>YAKUZA BOSSES </Title>
        <div className="m-5">
          <Fade cl bottom>
            <div className={classes["card-container"]}>
              <TeamCard
                darkMode={darkModeCtx.darkMode}
                src={DMC}
                name="DMC V12"
                role="Founder / CEO"
                twitter="https://twitter.com/Yassine61136539"
              />
              <TeamCard
                darkMode={darkModeCtx.darkMode}
                src={Arhood}
                name="Arhood"
                role="Founder / Marketing Lead"
                twitter="https://twitter.com/ArmanNejadi"
              />
              <TeamCard
                darkMode={darkModeCtx.darkMode}
                src={YakuzaDaddy}
                name="Yakuza Daddy"
                role="Founder / CFO"
                twitter="https://twitter.com/YakuzaDaddy"
              />
            </div>
            <div className={classes["card-container"]}>
              <TeamCard
                darkMode={darkModeCtx.darkMode}
                src={Gizmo}
                name="Gizmo"
                role="Co Founder / CTO"
                twitter="https://twitter.com/0xGizmo"
              />
              <TeamCard
                darkMode={darkModeCtx.darkMode}
                src={Slutty}
                name="SLUTTY CRYPTO"
                role="Head of Collabs"
                twitter="https://twitter.com/SluttyNft"
              />

            </div>
          </Fade>
        </div>
        <NextSection href="#faq" />
      </Container>
    </section>
  );
};

export default Team;
