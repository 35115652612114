import classes from "./Header.module.css";
import { useContext } from "react";

import Container from "../../UI/Container";
import NextSection from "../../UI/NextSection";
import DarkMode from "../../UI/DarkMode";

import Navbar from "../Navbar/Navbar";

import HeaderText from "./Header-text";
import HeaderCards from "./Header-Cards";

import darkModeContext from "../../store/mode-context";

const Header = () => {
  const darkModeCtx = useContext(darkModeContext);

  return (
    <header
      className={
        classes.header + " " + (darkModeCtx.darkMode && classes["dark-header"])
      }
    >
      <Navbar />
      <Container>
        <div className="row">
          <div className="col-xl-6">
            <HeaderText darkMode={darkModeCtx.darkMode} />
          </div>
          <div className="col-xl-6">
            <HeaderCards />
          </div>
        </div>
        <DarkMode className="d-md-none d-block mt-3 mb-3" />
        <NextSection href="#mint" />
      </Container>
    </header>
  );
};

export default Header;
