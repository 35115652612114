import classes from "./Icon.module.css";

import { useContext } from "react";

import darkModeContext from "../store/mode-context";

const Icon = (props) => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <li class="nav-item">
      <a class="nav-link" href={props.link} target="_blank">
        <img
          className={
            classes.icon + " " + (darkModeCtx.darkMode && classes.dark)
          }
          src={props.src}
          alt={props.alt}
        />
      </a>
    </li>
  );
};

export default Icon;
