import classes from "./Mint-Media.module.css";

import { useContext } from "react";

import XIcon from "../../assets/Icons/XIconBlack.png";
import OpenSeaIcon from "../../assets/Icons/OpenSeaIconBlack.png";
import DiscordIcon from "../../assets/Icons/DiscordIconBlack.png";

import XIconDark from "../../assets/Icons/XIcon.png";
import OpenSeaIconDark from "../../assets/Icons/OpenSeaIcon.png";
import DiscordIconDark from "../../assets/Icons/DiscordIcon.png";

import darkModeContext from "../../store/mode-context";

const MintMedia = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <div className={classes["social-media"]}>
      <h4>JOIN-US</h4>
      <div className={classes.logo}>
        <img
          src={darkModeCtx.darkMode ? XIconDark : XIcon}
          alt="Twitter X Logo"
        />
        <p>
          @YakuzaIncNFT{" "}
          <span className="red-color">
            <i class="bi bi-patch-check"></i>
          </span>
        </p>
      </div>
      <div className={classes.logo}>
        <img
          src={darkModeCtx.darkMode ? OpenSeaIconDark : OpenSeaIcon}
          alt="OpenSea Logo"
        />
        <p>
          Yakuza Inc. Official{" "}
          <span className="red-color">
            <i class="bi bi-patch-check"></i>
          </span>
        </p>
      </div>
      <div className={classes.logo}>
        <img
          src={darkModeCtx.darkMode ? DiscordIconDark : DiscordIcon}
          alt="Discord X Logo"
        />
        <p>
          Yakuza Inc.{" "}
          <span className="red-color">
            <i class="bi bi-patch-check"></i>
          </span>
        </p>
      </div>
    </div>
  );
};

export default MintMedia;
