import classes from "./Gachapon-Machine.module.css";

import { useState } from "react";
import Fade from "react-reveal/Fade";

import Gachapon from "../../assets/Imgs/Gashapon.png";
import GachaponAnimation from "../../assets/Imgs/Cachapon_Animation.gif";

const GachaponMachine = () => {
  const [currentImage, SetImage] = useState(Gachapon);

  const OnClickHandler = () => {
    // IF
    SetImage(GachaponAnimation);
    setTimeout(() => {
      SetImage(Gachapon);
    }, 850);
  };

  return (
    <Fade bottom>
      <button
        type="button"
        className={classes["gachapon-button"]}
        onClick={OnClickHandler}
        data-bs-toggle="modal"
        data-bs-target="#GachaponModal"
      >
        <img className={classes["gachapon-img"]} src={currentImage} />
      </button>
    </Fade>
  );
};

export default GachaponMachine;
