import classes from "./Header-text.module.css";

import Button from "../../UI/Button";

import Fade from "react-reveal/Fade";

const HeaderText = (props) => {
  return (
    <div className={classes["header-text"]}>
      <Fade bottom>
        <h1>
          Welcome to the
          <br /> <span className="red-color">Yakuza INC</span> Family
        </h1>
        <p className={props.darkMode && classes.dark}>
          We're more than just an NFT project: we aim to lead the revolution in
          digital assets. By providing our members with investment
          opportunities, voting rights in our DAO, and resources/utilities
          through our network partners, we are setting a new standard in the
          digital realm.
        </p>
      </Fade>
      <div className={classes.buttons}>
        <Fade left>
          <Button
            link="https://opensea.io/collection/yakuza-inc-official"
            label="YAKUZA INC"
            className={classes.button}
          />
          <Button
            link="https://opensea.io/collection/yakuza-elite"
            label="YAKUZA ELITE"
            className={classes["black-button"]}
          />
        </Fade>
      </div>
    </div>
  );
};

export default HeaderText;
