import classes from "./Gachapon.module.css";

import Container from "../../UI/Container";
import Title from "../../UI/Title";
import NextSection from "../../UI/NextSection";

import GachaponMachine from "./Gachapon-Machine";
import GachaponText from "./Gachapon-Text";
import GachaponBalance from "./Gachapon-Balance";

import { useContext } from "react";

import darkModeContext from "../../store/mode-context";

const Gachapon = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <section
      className={
        classes.gachapon + " " + (darkModeCtx.darkMode && classes.dark)
      }
      id="gachapon"
    >
      <Container className={classes.container}>
        <Title>GACHAPON</Title>
        <div className={"row " + classes["machine-text-container"]}>
          <div className="col-lg-6">
            <GachaponMachine />
          </div>
          <div className="col-lg-6">
            <GachaponText darkMode={darkModeCtx.darkMode} />
            <GachaponBalance />
          </div>
        </div>
        <NextSection href="#team" />
      </Container>
    </section>
  );
};

export default Gachapon;
