import { Fragment } from "react";
import classes from "./Title.module.css";

import Fade from "react-reveal/Fade";

const Title = (props) => {
  return (
    <Fragment>
      <Fade bottom>
        <h2 className={classes.title}>{props.children}</h2>
        <div className={classes.progress}>
          <div className={classes.inner}></div>
        </div>
      </Fade>
    </Fragment>
  );
};

export default Title;
