import classes from "./Mint.module.css";
import { useContext } from "react";

import Container from "../../UI/Container";
import NextSection from "../../UI/NextSection";
import Title from "../../UI/Title";
import Button from "../../UI/Button";
import Fade from "react-reveal/Fade";

import yakuzainc from "../../assets/Imgs/YakuzaInc.png";
import lelouch from "../../assets/Imgs/6.gif";

import MintMedia from "./Mint-Media";
import MintProgress from "./Mint-Progress";

import darkModeContext from "../../store/mode-context";

const Mint = () => {
  const darkModeCtx = useContext(darkModeContext);

  return (
    <section
      className={classes.mint + " " + (darkModeCtx.darkMode && classes.dark)}
      id="mint"
    >
      <Container>
        <Title>BE ONE OF US</Title>
        <div className="row">
          <div className="col-lg-5">
            <Fade left>
              <div className={classes.card}>
                <div className={classes.img}>
                  <div
                    style={{
                      backgroundImage: `url(${yakuzainc})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
                <div className={classes.text}>
                  <h4>
                    Join Our Community{" "}
                    <i className="bi bi-patch-check-fill red-color"></i>
                  </h4>
                  <p>
                    At Yakuza Inc., every member is a vital part of our journey.
                    Together, we're not just building a portfolio of digital
                    assets; we're crafting a legacy of digital excellence and
                    community strength. Join us, and be part of the movement!
                  </p>
                  <Button
                    link="https://opensea.io/collection/yakuza-inc-official"
                    className={classes.button}
                  />
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-lg-7">
            <Fade right>
              <div className={classes.card2}>
                <div className={classes.profile}>
                  <div
                    style={{
                      backgroundImage: `url(${lelouch})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
                <div className={classes.text}>
                  <p className={classes.quoate}>
                    "The human heart is the source of all our power. We fight
                    with the power of our hearts."
                  </p>
                  <small>Lelouch Lamperouge</small>
                </div>
              </div>
              <MintProgress />
              <MintMedia />
            </Fade>
          </div>
        </div>
        <NextSection href="#about" />
      </Container>
    </section>
  );
};

export default Mint;
