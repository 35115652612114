import classes from "./Navbar-Brand.module.css";

import { useContext } from "react";

import darkModeContext from "../../store/mode-context";

import logo from "../../assets/Imgs/Logo.png";
import logoDark from "../../assets/Imgs/LogoDark.png";

const NavbarBrand = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <div className="navbar-brand">
      <img
        className={classes.logo}
        src={darkModeCtx.darkMode ? logoDark : logo}
        alt="Yakuza Inc Logo"
      />
    </div>
  );
};

export default NavbarBrand;
