import classes from "./About-Text.module.css";

import Button from "../../UI/Button";

import Fade from "react-reveal/Fade";

import yakuza1 from "../../assets/Imgs/1.png";
import yakuza2 from "../../assets/Imgs/2.png";
import yakuza3 from "../../assets/Imgs/3.png";
import yakuza4 from "../../assets/Imgs/4.png";

import yakuzaElit2 from "../../assets/Imgs/2.gif";
import yakuzaElit4 from "../../assets/Imgs/4.gif";

const AboutText = (props) => {
  return (
    <div className={classes.section}>
      <div className={"row"}>
        <div className="col-lg-6">
          <Fade bottom>
            {" "}
            <div className={classes.text}>
              <h3>THE ADVANTAGES OF JOINING YAKUZA INC</h3>
              <p className={props.darkMode && classes.dark}>
                By owning a Yakuza Inc. NFT you receive the following:
              </p>
              <ul className={props.darkMode && classes.dark}>
                <li>Strategic Investment opportunities through CITCAP</li>
                <li>Whitelist spots and partner project giveaways</li>
                <li>Direct access to partner servers and giveaways</li>
                <li>Alpha insights in NFTs, coins, and airdrops</li>
                <li>$TEMPURA utility token & Gachapon Game</li>
                <li>More TBA at a later time</li>
              </ul>
            </div>
          </Fade>
        </div>
        <div className="col-lg-2"></div>
        <div className="col-lg-4">
          <Fade>
            <div className={classes["imgs-container"]}>
              <img className={classes.img} src={yakuza1} />
              <img className={classes.img} src={yakuza2} />
              <img className={classes.img} src={yakuza3} />
              <img className={classes.img} src={yakuza4} />
            </div>
          </Fade>
        </div>
      </div>
      <div className={"row mt-4"}>
        <div className="col-lg-4">
          <Fade>
            <div className="d-flex flex-wrap justify-content-center">
              <img className={classes.img} src={yakuzaElit2} />
              <img className={classes.img} src={yakuzaElit4} />
            </div>
          </Fade>
        </div>

        <div className="col-lg-8">
          <Fade bottom>
            {" "}
            <div className={classes.text}>
              <h3 className="red-color m-lg-0 mb-lg-3 m-3">YAKUZA ELITE</h3>
              <p className={props.darkMode && classes.dark}>
                When simply being a part of the Yakuza family doesn't quite
                quench your thirst for exclusivity, step up to join the ELITE.
                As an ELITE member, you'll enjoy enhanced allocations,
                additional whitelist spots, and a suite of extra perks that
                further elevate your status in our distinguished family tree.
              </p>
              <Button
                link="https://opensea.io/collection/yakuza-elite"
                className={classes.button}
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default AboutText;
