import classes from "./Team-Card.module.css";

const TeamCard = (props) => {
  return (
    <div className={classes.card + " " + (props.darkMode && classes.dark)}>
      <div className={classes.img}>
        <div
          style={{
            backgroundImage: `url(${props.src})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
      <div className={classes.text}>
        <h3>{props.name}</h3>
        <p>{props.role}</p>
        <a target="_blank" className={classes.link} href={props.twitter}>
          <i class="bi bi-twitter-x"></i>
        </a>
      </div>
    </div>
  );
};

export default TeamCard;
