import classes from "./Gachapon-Modal.module.css";

import { useContext } from "react";

import darkModeContext from "../../store/mode-context";

const GachaponModal = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <div
      className={"modal fade"}
      id="GachaponModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div
          id={darkModeCtx.darkMode && classes.dark}
          className={"modal-content "}
        >
          <div className={"modal-header " + classes.header}>
            <h2 className={classes.title} id="exampleModalLabel">
              GACHAPON
            </h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p className={classes["win-text"]}>COMING SOON!!</p>
          </div>
          <div class={"modal-footer " + classes.footer}>
            <button
              type="button"
              class={classes.button}
              data-bs-dismiss="modal"
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GachaponModal;
