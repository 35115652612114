import classes from "./Navbar.module.css";

import Container from "../../UI/Container";

import NavbarBrand from "./Navbar-Brand";
import NavbarNav from "./Navbar-Nav";

import { Fragment } from "react";

const Navbar = () => {
  return (
    <nav className={classes.nav + " navbar navbar-expand-lg"}>
      <Container>
        <div className="d-block d-lg-flex justify-content-between w-100">
          <NavbarBrand />
          <NavbarNav type="menu" />
          <NavbarNav type="" />
        </div>
      </Container>
    </nav>
  );
};

export default Navbar;
