import "./App.css";

import Header from "./components/Header/Header";
import Mint from "./components/Mint/Mint";
import About from "./components/About/About";
import Gachapon from "./components/Gachapon/Gachapon";
import GachaponModal from "./components/Gachapon/Gachapon-Modal";
import RoadMap from "./components/RoadMap/RoadMap";
import Team from "./components/Team/Team";
import Faq from "./components/FAQ/Faq";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Mint />
      <About />
      <Gachapon />
      <Team />
      <Faq />
      <Footer />
      <GachaponModal />
    </div>
  );
}

export default App;
