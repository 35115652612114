import classes from "./Mint-Progress.module.css";

const MintProgress = () => {
  return (
    <div className={classes["progress-bg"]}>
      <h4>1972/1972</h4>
      <p>Tokens Minted</p>
      <div className={classes.progress}>100%</div>
    </div>
  );
};

export default MintProgress;
