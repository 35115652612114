import classes from "./DarkMode.module.css";

import { useContext, useEffect } from "react";

import darkModeContext from "../store/mode-context";

const DarkMode = (props) => {
  const darkModeCtx = useContext(darkModeContext);
  const style = classes["checkbox-wrapper-54"] + " " + props.className;

  return (
    <div className={style}>
      <label className={classes["switch"]}>
        <input
          type="checkbox"
          onChange={darkModeCtx.SwitchDarkMode}
          checked={darkModeCtx.darkMode}
        />
        <span className={classes["slider"]}></span>
      </label>
    </div>
  );
};

export default DarkMode;
