import classes from "./Button.module.css";

const Button = (props) => {
  const style = classes.button + " " + props.className;
  return (
    <button className={style}>
      <a href={props.link} target="_blank">
        {props.label}
        <i class="bi bi-arrow-right-short"></i>
      </a>
    </button>
  );
};

export default Button;
