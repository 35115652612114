import classes from "./Header-Cards.module.css";

import Card from "../../UI/Card";

import { useContext } from "react";

import Luffy from "../../assets/Imgs/Luffy.png";
import Gintoki from "../../assets/Imgs/Gintoki.png";
import Tanjiro from "../../assets/Imgs/Tanjiro.png";

import darkModeContext from "../../store/mode-context";

import Fade from "react-reveal/Fade";

const HeaderCards = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <Fade bottom>
      <div className={classes.cards}>
        <hr className={classes.line1} />
        <hr className={classes.line2} />
        <Card
          src={Gintoki}
          className={
            classes.card2 + " " + (darkModeCtx.darkMode && classes.dark)
          }
          label="Gintoki"
          desc="Yakuza Inc. #745"
        />
        <Card
          src={Tanjiro}
          className={
            classes.card3 + " " + (darkModeCtx.darkMode && classes.dark)
          }
          label="Tanjiro"
          desc="Yakuza Inc. #5"
        />{" "}
        <Card
          src={Luffy}
          className={
            classes.card1 + " " + (darkModeCtx.darkMode && classes.dark)
          }
          label="Luffy"
          desc="Yakuza Inc. #2"
        />
      </div>
    </Fade>
  );
};

export default HeaderCards;
