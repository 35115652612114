import classes from "./Gachapon-Balance.module.css";

import Button from "../../UI/Button";

const GachaponBalance = () => {
  return (
    <div className={"row " + classes.parent}>
      <div className="col-lg-6 mb-lg-0 mb-5">
        <Button className={classes.button} label="CONNECT " />
      </div>
        <div className={classes.balance + " col-lg-6"}>
          $TEMPURA BALANCE : <span className="red-color"> 0$</span>
        </div>
    </div>
  );
};

export default GachaponBalance;
