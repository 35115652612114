import classes from "./Nav-link.module.css";

import { useContext } from "react";

import darkModeContext from "../../store/mode-context";

const NavItem = (props) => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <li class="nav-item">
      <a
        className="nav-link "
        id={darkModeCtx.darkMode ? classes["dark-link"] : classes.link}
        href={props.link}
      >
        {props.label}
      </a>
    </li>
  );
};

export default NavItem;
