import classes from "./Faq.module.css";

import { Fade } from "react-reveal";

import Container from "../../UI/Container";
import Title from "../../UI/Title";

import FaqCard from "./Faq-Card";

import { useContext } from "react";

import darkModeContext from "../../store/mode-context";

const Faq = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <section
      className={classes.faq + " " + (darkModeCtx.darkMode && classes.dark)}
      id="faq"
    >
      <Container>
        <Title>FAQ </Title>
        <Fade bottom>
          <div className={classes["faq-cards"]}>
            <FaqCard
              darkMode={darkModeCtx.darkMode}
              title="How many Yakuza Inc. Gen0 NFTs are available?"
              text="Only 1972 Yakuza Inc. Gen0 NFTs will be available to be minted.
              25 of them are legendary."
            />
            <FaqCard
              darkMode={darkModeCtx.darkMode}
              title="What is the DAO voting process?            "
              text="The more Yakuza NFTs you hold, the more voting power you hold. Voting is done via snapshot with voting links sent out through our Discord's announcements."
            />
            <FaqCard
              darkMode={darkModeCtx.darkMode}
              title="What will the DAO vote on?            "
              text="Which Neo Tokyo NFTs to purchase, what to do with $BYTES yielded, funding budgets for game development and marketing campaigns, changes and improvements tied to $TEMPURA tokenomics, and anything else the DAO wishes to propose."
            />
            <FaqCard
              darkMode={darkModeCtx.darkMode}
              title="How will the DAO community wallet be funded?            "
              text="Most DAOs only buy blue chips and receive revenue from Opensea. We have setup multiple avenues of revenue for our DAO, including initial mint revenue, Opensea royalties, $BYTES yield, and Yakuza's mobile game royalties.            "
            />
            <FaqCard
              darkMode={darkModeCtx.darkMode}
              title="How do I contact the team?"
              text="For any concerns, issues or proposals, please join the Discord and feel free to ask any of our team members any questions you have."
            />
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Faq;
