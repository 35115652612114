import { Fragment, useState } from "react";

import classes from "./Faq-Card.module.css";

import { Fade } from "react-reveal";

const FaqCard = (props) => {
  const [display, setDisplay] = useState(false);

  const OnClickHandler = () => {
    setDisplay(!display);
  };

  return (
    <Fragment>
      <div className={classes.card + " " + (props.darkMode && classes.dark)}>
        <div className={classes.title + " " + (props.darkMode && classes.dark)}>
          <h2>{props.title}</h2>
          <button onClick={OnClickHandler}>
            {display ? (
              <i class="bi bi-dash-circle-fill"></i>
            ) : (
              <i class="bi bi-plus-circle-fill"></i>
            )}
          </button>
        </div>
      </div>
      {display && (
        <Fade>
          <div
            className={classes.content + " " + (props.darkMode && classes.dark)}
          >
            <p>{props.text}</p>
          </div>
        </Fade>
      )}
    </Fragment>
  );
};

export default FaqCard;
