import classes from "./Footer.module.css";

import Container from "../../UI/Container";
import { useContext } from "react";

import darkModeContext from "../../store/mode-context";

import Logo from "../../assets/Imgs/FooterLogo.png";

const Footer = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <footer
      className={classes.footer + " " + (darkModeCtx.darkMode && classes.dark)}
    >
      <Container>
        <hr className={classes.hr} />
        <img className={classes.logo} src={Logo} alt="Yakuza Inc Logo" />
      </Container>
    </footer>
  );
};

export default Footer;
