import classes from "./About.module.css";

import { useContext } from "react";

import Container from "../../UI/Container";
import NextSection from "../../UI/NextSection";
import Title from "../../UI/Title";

import AboutText from "./About-Text";

import darkModeContext from "../../store/mode-context";

const About = () => {
  const darkModeCtx = useContext(darkModeContext);
  return (
    <section
      className={classes.about + " " + (darkModeCtx.darkMode && classes.dark)}
      id="about"
    >
      <Container>
        <Title>WHAT SETS US APART ?</Title>
        <AboutText darkMode={darkModeCtx.darkMode} />
        <NextSection href="#gachapon" />
      </Container>
    </section>
  );
};

export default About;
